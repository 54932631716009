import Work from './Work';
import Bio from './Bio';
import Play from './Play';
import NotFound from './NotFound';
import PasswordGate from './Password';

export const pages = [
  {
    path: '/',
    element: <Work />,
  },
  {
    path: '/bio',
    element: <Bio />,
  },
  {
    path: '/password-gate',
    element: <PasswordGate />,
  },
  {
    path: '/play',
    element: <Play />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
