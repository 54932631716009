import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Nav from '../../components/Nav'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const DefaultLayout = ({ pageTitle, children }) => {
  return (
    <div className="default-layout">
      <Header />
      <Nav />
      <Container>
        <Row>
          <Col className="content-wrapper" lg={{ span: 12, offset: 0 }} xl={{ span: 6, offset: 3 }}>
            { children }
          </Col>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  )
}

DefaultLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired
}

export default DefaultLayout;
