import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'

const Hero = ({
  className
}) => {
  const [timeOfDay, setTimeOfDay] = useState('');

  useEffect(() => {
    const today = new Date();
    const hour = today.getHours();

    let time;
    if (hour < 12) {
      time = 'morning';
    } else if (hour < 17) {
      time = 'afternoon';
    } else {
      time = 'evening';
    }

    setTimeOfDay(time);
  }, []);

  return (
    <section className={classnames('hero', className)}>
      <h1>G’{timeOfDay}, <NavLink to="bio">I&apos;m Drew</NavLink></h1>
      <p>
        Designer @ Publix - Fascinated by AI, AR/VR - Mentored 30+ students @ CareerFoundry -
        Impacted 100M+ users at companies such as Walmart, It Works!, and Tropicana.
      </p>
    </section>
  );
};

export default Hero;
