import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Navbar = () => {
  const location = useLocation();

  const getNavClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className="navbar">
      <Link to="/" className={`nav-link ${getNavClass('/')}`}>work</Link>
      <Link to="/play" className={`nav-link ${getNavClass('/play')}`}>play</Link>
      <Link to="/bio" className={`nav-link ${getNavClass('/bio')}`}>bio</Link>
    </nav>
  );
};

export default Navbar;
