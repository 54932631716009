import React from 'react'
import DefaultLayout from '../../layouts/Default'
import Hero from '../../components/Hero'
import CaseStudy from '../../components/CaseStudy'
import TropicanaPreviewImg from '../../assets/images/tropicana_preview-panel.png'
import TropicanaDisplayImg from '../../assets/images/tropicana_display.png'
import TropicanaDeck from '../../assets/decks/tropicana_case-study_master.pdf'
import WalmartPreviewImg from '../../assets/images/walmart_preview-panel.png'
import WalmartDisplayImg from '../../assets/images/walmart_display.png'
import WalmartDeck from '../../assets/decks/wm_case-study_master.pdf'
import PublixPreviewImg from '../../assets/images/publix_preview-panel.png'
import PublixDisplayImg from '../../assets/images/publix_display.png'
import PublixDeck from '../../assets/decks/publix_tax_case-study_master.pdf'

const Work = () => {
  let _publixDeckLink = `/password-gate?redirect=${PublixDeck}`

  return (
    <DefaultLayout pageTitle="work">
      <Hero className="work-hero" />
      <div className="work-case-studies">
        <CaseStudy
          title="Delivery App"
          company="Tropicana"
          description="Now used nationwide, I uncovered costly business problems and found a new, cost effective solution that resulted in saving $225k per year and increased driver income."
          previewImageSrc={TropicanaPreviewImg}
          imageSrc={TropicanaDisplayImg}
          presentationLink={TropicanaDeck}
          storyContent="Tasked with designing a kiosk app for Tropicana’s logistics challenges, I uncovered deeper inefficiencies during discovery and proposed a streamlined mobile web app instead. This shift not only reduced $225K in annual detention costs but also improved driver income, eliminated traffic bottlenecks, and achieved over 90% adoption. By aligning user needs with business goals, we delivered a scalable solution now rolling out nationwide—proving that meaningful design goes beyond the initial brief."
        />
        <CaseStudy
          title="Enterprise System"
          company="Publix"
          description="Leading an ongoing multi-year effort that modernizes Publix's mainframe systems into a new, task effecient experience."
          previewImageSrc={PublixPreviewImg}
          imageSrc={PublixDisplayImg}
          presentationLink={_publixDeckLink}
          storyContent="I led a large-scale, multi-year effort to redesign and modernize many of Publix's outdated internal systems. Overcoming growing pains and process gaps, we began the project by creating a more efficient, scalable, and user-friendly tax system that now manages 300k+ items. Through our lean design approach, we were able to reduce the task completion time by 98%, improve auditing and save Publix money on errors and storage cost."
        />
        <CaseStudy
          title="Out of Stock Experience"
          company="Walmart"
          description="Recaptured customers shopping for backordered items by implementing a new customer notification experience."
          previewImageSrc={WalmartPreviewImg}
          imageSrc={WalmartDisplayImg}
          presentationLink={WalmartDeck}
          storyContent="Walmart’s CA and MX mobile app and website lacked an effective backorder system, which often results in users bouncing to a competitor's website to find out-of-stock items. Aiming to improve the user experience and recapture the customer after items gained stock, we implemented a backorder system that allows users to sign up for notifications when items are back in stock, and provides a seamless experience when adding the item to their cart."
        />
    </div>
    </DefaultLayout>
  )
}

export default Work;
