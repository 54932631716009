import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import DefaultLayout from '../../layouts/Default'
import BjjImage from '../../assets/images/bio-bjj.jpg'
import JapaneseImage from '../../assets/images/bio-japanese.jpg'

const Bio = () => {
  return (
    <DefaultLayout pageTitle="play">
      <Container>
        <Row>
          <Col>
              <img className="bio__image" src={BjjImage} alt="receiving my third in jiu-jitsu"/>
              <caption>BJJ - Receiving my 3rd stripe (white belt)</caption>
          </Col>
        </Row>
        <Row>
          <Col>
              <img className="bio__image" src={JapaneseImage} alt="my japansese classmates"/>
              <caption>Japanese - Currently N4 (intermediate). I have met so many awesome people over the past 3 years.</caption>
          </Col>
        </Row>
        <Row>
          <Col>
              <iframe className="video" width="560" height="315" src="https://www.youtube.com/embed/Q046SDTzM48?si=Nt5AEmqvYOnW9DuU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <caption>Video editing is my first love. This recent video I made visiting my dad who passed soon after.</caption>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  )
}

export default Bio;
