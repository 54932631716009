import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import DefaultLayout from '../../layouts/Default'
import Quote from '../../components/Quote'
import BioImage from '../../assets/images/bio-drew.jpg'

const Bio = () => {
  return (
    <DefaultLayout pageTitle="bio">
      <Container>
        <Row>
          <Col>
            <img className="bio__image" src={ BioImage } alt="a portrait shot of me"/>
          </Col>
        </Row>
        <Row>
          <Col>
            <header>
              <h2>Small town beginnings</h2>
            </header>
            <h3>Ever heard of Huntington, W.V.?</h3>
            <p>You may have heard of Marshall University, the Mothman, or perhaps seen the documentary about the drug crisis. Luckily my parents moved my family to Florida at a young age.</p>
            <p>With the lack of opportunities (and sights of abandoned buildings and torn roads) the town I called home, gives me the drive to be the best version of myself. For this, I'm grateful for my small-town beginnings.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Quote source="Nichole - Amazon - Sr. Director, Digital CX, Lifecycle & Loyalty">
              "Drew has a natural gift for delivering this guidance in a respectful and productive way. I had the chance to watch Drew really refine his ability to guide stakeholders down the best path, even when it wasn’t their original path."
            </Quote>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Mentored 30+ designers</h2>
            <p>In addition to my professional work, I am passionate about giving back to the community through mentoring students at <a href="http://www.careerfoundry.com" target="_blank" rel="noopener noreferrer">CareerFoundry</a>, an online bootcamp that offers specialized programs in UX design, UI design, and data analytics. Additionally, I've mentored both junior and senior designers and developers through company programs.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Quote source="Stacy - Cigna - UX Designer">
              "I am one of Drew's UX mentees at CareerFoundry, and he is the only reason I know what I'm doing at the bootcamp. He is driven, organized, fun, knowledgeable, and genuine. His love for his students and his desire to see them grow show in the countless hours he spends meeting with me weekly."
            </Quote>
          </Col>
        </Row>
        <Row>
          <Col>
            <Quote source="Alexandria - Superlanet - IT Manager">
              "Drew was hands down the best mentor I could've asked for during my time at Careerfoundry! Thanks to Drew's guidance, I've seen tremendous growth as a designer. His mentorship has been instrumental in shaping my journey and building my confidence in the field of UX design."
            </Quote>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  )
}

export default Bio;
