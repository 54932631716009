import React from 'react'
import ReactModal from 'react-modal'
import Button from '../Button'

ReactModal.setAppElement('#root');

const Modal = ({
  isOpen,
  onRequestClose,
  contentLabel = 'Modal',
  children,
  className = '',
  presentationLink,
  overlayClassName = ''
}) => {

  let presentationEl

  if (presentationLink) { presentationEl = <Button to={presentationLink} link primary>open presentation</Button>}

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className={`modal-content ${className}`}
      overlayClassName={`modal-overlay ${overlayClassName}`}
    >
      <button onClick={onRequestClose} className="modal-close">×</button>
      <div className="modal-body">
        {children}
      </div>
      <div className="actions">
        <Button ghost onClick={onRequestClose}>close</Button>
        {presentationEl}
      </div>
    </ReactModal>
  );
};

export default Modal;
