import React from 'react';
import { Link } from 'react-router-dom'
import SiteLogo from '../../assets/logos/SiteLogo'

const Header = () => {
  return (
    <section className="header">
      <Link to="/" className="header__logo"><SiteLogo /></Link>
      <div className="header__nav">
        <a href="https://www.linkedin.com/in/itsmedrew/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://www.itsmedrew.com/resume/drew-roberts_resume.pdf" target="_blank" rel="noopener noreferrer" external="true">Resume</a>
      </div>
    </section>
  );
};

export default Header;
