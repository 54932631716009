import React from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {NavLink } from 'react-router-dom'

const ButtonPrimary = ({
  to,
  children,
  className,
  primary,
  ghost,
  text,
  type,
  link,
  onClick }) => {
  const buttonType = primary ? 'primary' : ghost ? 'ghost' : text ? 'text' : '';

  if (link) {
    return (
      <a className={classnames({[`button-${buttonType}`]: true}, className)} href={to} target="_blank" rel="noopener noreferrer">{children}</a>
    )
  }

  if (onClick) {
    return (
      <button className={classnames({[`button-${buttonType}`]: true}, className)} type={type || null} onClick={onClick} href={to} target="_blank" rel="noopener noreferrer">{children}</button>
    )
  }

  return (
    <NavLink className={classnames({[`button-${buttonType}`]: true}, className)} to={to}>{children}</NavLink>
  )
}

ButtonPrimary.propTypes = {
  to: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.bool,
  primary: PropTypes.bool,
  ghost: PropTypes.bool,
  text: PropTypes.bool,
  onClick: PropTypes.func
}

export default ButtonPrimary;
