import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'

const CaseStudy = ({
  title,
  company,
  description,
  previewImageSrc,
  imageSrc,
  presentationLink,
  storyContent
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  let presentationEl

  if (presentationLink) {
    const checkTarget = presentationLink ? '_blank' : false;

    presentationEl = <a href={presentationLink} target={checkTarget} rel="noopener noreferrer">
      Open presentation
    </a>
  }

  return (
    <section className="case-study">
      {/* Left Section */}
      <div className="case-study__left">
        {/* Title and Company (Shown on Rest) */}
        <h3 className="case-study__title">{title}</h3>
        <p className="case-study__company">{company}</p>

        {/* Image (Shown on Hover) */}
        <div
          className="case-study__image"
          style={{ backgroundImage: `url(${previewImageSrc || 'https://picsum.photos/175/175'})` }}
        ></div>
      </div>

      {/* Right Section */}
      <div className="case-study__right">
        <p className="case-study__description">{description}</p>
        <div className="case-study__links">
          {presentationEl}
          <button rel="noopener noreferrer" onClick={openModal} className="case-study_button">
            Read short story
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal
        className="case-study-modal"
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        presentationLink={presentationLink}
        contentLabel="Short Story Modal"
      >
      <img src={imageSrc || 'https://picsum.photos/600/300'} alt=""/>
      
        <h2>{title}</h2>
        <h3>{company}</h3>
        <p>{storyContent}</p>
      </Modal>
    </section>

  );
};

CaseStudy.propTypes = {
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  previewImageSrc: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  presentationLink: PropTypes.string,
  storyContent: PropTypes.string.isRequired
}

export default CaseStudy;
