import React from 'react'

const Footer = () => {
  const year = (new Date()).getFullYear();

  return (
    <footer className="site-footer">
      <span className="site-footer__text">
        { year }. Designed with ☕️ by Drew. Developed with React js.
      </span>
    </footer>
  );
};

export default Footer;
